import 'ionicons/dist/css/ionicons.min'
import 'src/application'
import $ from 'jquery'
import './bootstrap'
import hljs from 'highlight.js'
import ClipboardJS from 'clipboard'

import Rails from '@rails/ujs'
Rails.start()
hljs.initHighlightingOnLoad()
window.hljs = hljs
window.$ = $

document.addEventListener("DOMContentLoaded", function(event) {
  var clipboard = new ClipboardJS('.copy')

  clipboard.on('success', function(e) {
    $(e.trigger).tooltip({ trigger: 'manual', title: 'コピーしました' })
    $(e.trigger).tooltip('show')
    setTimeout(function() {
      $(e.trigger).tooltip('hide')
    }, 500)
  })

  clipboard.on('error', function(e) {
    $(e.trigger).tooltip({ trigger: 'manual', title: '失敗しました' })
    $(e.trigger).tooltip('show')
    setTimeout(function() {
      $(e.trigger).tooltip('hide')
    }, 500)
  })
})
